import React from "react"

import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Container from "components/container"
import Hero from "components/hero"
import Layout from "components/layout"
import SeparatorH2Style from "components/separator-h2-style"

type Category = {
  categoryId: string
  title: string
}

type FAQ = {
  node: FAQContent
}

type FAQContent = {
  id: string
  frontmatter: FAQFrontmatter
  html: string
}

type FAQFrontmatter = {
  question: string
  faqCategory: string
}

const FAQ = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
`

const Question = styled.div`
  font-weight: bold;
  flex: 1;
  min-width: 180px;
  margin: 5px;
`

const Answer = styled.div`
  flex: 2.5;
  min-width: 300px;
  margin: 5px;
`

const FAQs = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            filter: { frontmatter: { type: { eq: "faq" } } }
            sort: { fields: frontmatter___order }
          ) {
            edges {
              node {
                id
                frontmatter {
                  question
                  faqCategory
                }
                html
              }
            }
          }
          allFaqCategoryYaml {
            nodes {
              title
              categoryId
            }
          }
        }
      `}
      render={(data) => (
        <Container size="medium">
          <SeparatorH2Style>
            {data.allFaqCategoryYaml.nodes.map((category: Category) => (
              <div key={category.categoryId}>
                <h2>{category.title}</h2>
                {data.allMarkdownRemark.edges.map((faq: FAQ) => {
                  return (
                    faq.node.frontmatter.faqCategory == category.categoryId && (
                      <FAQ key={faq.node.id}>
                        <Question>{faq.node.frontmatter.question}</Question>
                        <Answer
                          dangerouslySetInnerHTML={{ __html: faq.node.html }}
                        />
                      </FAQ>
                    )
                  )
                })}
              </div>
            ))}
          </SeparatorH2Style>
        </Container>
      )}
    />
  )
}

const FAQPage = () => {
  return (
    <Layout
      title="FAQ"
      description="Get answers to some frequently asked questions"
    >
      <Hero title="Frequently Asked Questions" />
      <FAQs />
    </Layout>
  )
}

export default FAQPage
